@font-face {
  font-family: Nunito;
  src: url('./Nunito/Nunito-Regular.ttf');
}

@font-face {
  font-family: Nunito;
  font-style: italic;
  src: url('./Nunito/Nunito-Italic.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 700;
  src: url('./Nunito/Nunito-Bold.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 700;
  font-style: italic;
  src: url('./Nunito/Nunito-BoldItalic.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 900;
  src: url('./Nunito/Nunito-Black.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 900;
  font-style: italic;
  src: url('./Nunito/Nunito-BlackItalic.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 500;
  src: url('./Nunito/Nunito-Medium.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 300;
  src: url('./Nunito/Nunito-Light.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 300;
  font-style: italic;
  src: url('./Nunito/Nunito-LightItalic.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 100;
  src: url('./Nunito/Nunito-ExtraLight.ttf');
}

@font-face {
  font-family: Nunito;
  font-weight: 100;
  font-style: italic;
  src: url('./Nunito/Nunito-ExtraLightItalic.ttf');
}

@font-face {
  font-family: Rubik;
  src: url('./Rubik/Rubik-Regular.ttf');
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  src: url('./Rubik/Rubik-Italic.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  src: url('./Rubik/Rubik-Bold.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  font-style: italic;
  src: url('./Rubik/Rubik-BoldItalic.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 900;
  src: url('./Rubik/Rubik-Black.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 900;
  font-style: italic;
  src: url('./Rubik/Rubik-BlackItalic.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: url('./Rubik/Rubik-Medium.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 300;
  src: url('./Rubik/Rubik-Light.ttf');
}

@font-face {
  font-family: Rubik;
  font-weight: 300;
  font-style: italic;
  src: url('./Rubik/Rubik-LightItalic.ttf');
}
