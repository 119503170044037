@use "sass:math";
//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  Rubik, Roboto, Helvetica, Arial, sans-serif !default;
$font-family-serif:       Rubik, Roboto, "Times New Roman", Times, serif !default;

$font-family-title: Nunito, $font-family-sans-serif;
$font-family-base: $font-family-sans-serif !default;

$font-family-freight-text:  Rubik;
$font-family-freight-text-pro: Rubik;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-medium:        16px !default;
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-smaller:       10px;


//== Spaces
//
//## Define common spacing to use on padding and margin based in `rem`. Values based on 1rem=14px text.

$theme-space: 1rem;
$theme-space-nudge: math.div($theme-space, 16);
$theme-space-micro: math.div($theme-space, 8);
$theme-space-tiny: math.div($theme-space, 4);
$theme-space-small: math.div($theme-space, 2);
$theme-space-big: $theme-space*2;
$theme-space-gigantic: $theme-space*4;
$theme-space-enormous: $theme-space*8;


//== Transitions and delays

$ease-transition-value: ease 0.4s;

//== Inputs

$radio-border-radius: 2px;

//== Buttons

$button-default-border: 1px;

//== Columns
$default-center-width: 51%;

//== Paginations
$default-radius-pagination-button: 0.7rem;

//== Notifications
$default-box-width-notification: 400px;
$default-box-height-notification: 150px;

//== Dashboard
$max-box-width-dashboard: 1700px;
