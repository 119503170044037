@use '@angular/material' as mat;

@include mat.core();
/* You can add global styles to this file, and also import other style files */
@import '../src/assets/fonts/fonts.scss';
@import '../src/assets/styles/_mixins.scss';
@import 'assets/styles/_colors.scss';
@import '../src/assets/styles/_variables.scss';
@import '../src/assets/styles/bootstrap-grid-system';


/*
 *  @author     @version    @date           @description
 *  HSenevir    01          May 11, 2023    AFLL-17913 Fixed checkbox style after Angular 15 upgrade
 *  HSenevir    02          May 17, 2023    AFLL-17970 Fixed MFA snack bar style issues after Angular 15 upgrade
 *  PPareek     03          Jul 25, 2024    AFLL-21763 - Upgrade Angular to v18 and Material to V18 (M3)
 *  PPareek     04          Aug 21, 2024    AFLL-22017 - VDS Portal | Privacy Acceptance checkbox color
                                            is different
 */

/*
Applying custom angular material theming
*/
$vely-primary: mat.$cyan-palette;
$velys-accent: mat.$azure-palette;

$my-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $vely-primary,
    tertiary: $velys-accent
  )
));
//Applying custom theme to checkbox
html {
  @include mat.checkbox-theme($my-theme);
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: $font-family-base;
  min-width: 100%;
  width: 100%;
  padding: 0;
  background-color: $velys-secondary-white;
}

.text-center {
  text-align: center;
}

* {
  box-sizing: border-box;
  font-family: $font-family-base;
}

/*
VELYS Checkbox styling
*/
.velys-checkbox {
  .mat-mdc-checkbox-1-input {
    font-size: 12px;
    font-family: $font-family-base;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: $velys-primary-black;
    font-weight: normal;
  }

  .mdc-checkbox__background {
    width: 20px;
    height: 20px;
  }

  .mdc-checkbox__ripple {
    display: none;
  }
}

.velys-checkbox.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

@media only screen and (max-width: 768px) {
  .velys-checkbox {
    .mat-checkbox-layout {
      white-space: normal;
    }
  }
}

ul ul {
  list-style-type: disc;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.success-snack-toaster.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: #00A259 0% 0% no-repeat padding-box;
  border-radius: 16px !important;
  opacity: 1;
  white-space: nowrap;
  max-width: 60vw !important;
  color: #ffffff !important;

}

.failure-snack-toaster.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: #DF0050 0% 0% no-repeat padding-box;
  border-radius: 16px !important;
  opacity: 1;
  white-space: nowrap;
  max-width: 60vw !important;
  color: #ffffff !important;
}

.unsuccessful-failure-snack-toaster.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: #DF0050 0% 0% no-repeat padding-box;
  border-radius: 16px !important;
  opacity: 1;
  white-space: pre;
  max-width: 60vw !important;
  color: #ffffff !important;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .success-snack-toaster {
    margin-top: 25px !important;
  }

  .failure-snack-toaster {
    margin-top: 25px !important;
  }

  .unsuccessful-failure-snack-toaster {
    margin-top: 25px !important;
  }
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-focus-icon-color: #{$velys-blue};
  --mdc-checkbox-selected-icon-color: #{$velys-blue};
  --mdc-checkbox-selected-icon-color : #{$velys-blue};
  --mdc-checkbox-selected-hover-icon-color: #{$velys-blue};
  --mdc-checkbox-selected-hover-state-layer-color: #{$velys-blue};
  --mdc-checkbox-selected-focus-state-layer-color: #{$velys-blue};
  --mdc-checkbox-selected-pressed-state-layer-color: #{$velys-blue};
}