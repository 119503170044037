/*
DO maintain this list by separating each color family

DO retain consistency by only declaring 6-digit hex colors here
DO NOT add duplicate values with different color schemes
#000000 and rgb(0,0,0)

DO NOT declare colors with opacity < 1 in this file
DO use transparency at the point of using the color, NOT in this file. eg below
color: rgba($black, 0.2)

DO use color names that are meaningful across the site when declaring new colors
DO NOT name the colors with a reference to where it is used in the site, eg below
$velys-login-button: #00B4E1

*/


/* BLACK */
$black: #000000;
$velys-primary-black: #212121;
$velys-secondary-black: #202023;
$velys-tertiary-black: #272e31;

/* WHITE */
$white: #ffffff;
$velys-primary-white: #F7F7F7;
$velys-secondary-white: #F7F8FB;
$velys-tertiary-white: #F7F8FC;

/* GRAY */
$velys-gray: #707070;
$velys-primary-gray: #4A4A4A;
$velys-secondary-gray: #646464;
$velys-tertiary-gray: #9B9A9B;
$velys-lightish-gray: #d6d6d6;
$velys-ligh-gray: #D2D3D4;
$velys-light-gray: rgba($black, 0.16);
$velys-disabled-color: #9B9B9B;


$velys-grey: #B3B3B3;

/* BLUE */
$velys-blue: #00B4E1;
$velys-blue-dark: #1478B4;

/* RED */
$velys-red: #D0011B;

/*Green*/
$velys-green: #00B463;
